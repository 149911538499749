body {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  height: 100vh;
  justify-content: center;
  text-align: center;
  width: 95%;
  margin: 0 auto;
}

.header {
  display: inline-block;
  font-size: 2em;
  margin-bottom: 5px;
}

.sub-text {
  color: #999;
  font-size: 1.5em;
  line-height: 1.4em;
}

.links {
  height: 110px;
  margin-top: 20px;
  transition: height 0.8s ease-out;
  overflow: hidden;
}

.links.collapsed {
  height: 0;
}

.links-top {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}


.links .link {
  cursor: pointer;
  margin: 0 10px;
  padding: 11px;
}

.links .link p {
  font-size: 0.8em;
  margin-bottom: 0;
}

.fill-black {
  fill: #000;
}
